import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import cx from "classnames"
import styles from "./footerSection.module.scss"

import logo from "../images/pdb-logo-min-white.svg"

const FooterSection = () => {
  const query = useStaticQuery(graphql`
    query {
      file(name: { eq: "footer" }) {
        childMarkdownRemark {
          frontmatter {
            email
            phone
            linkedin
            image {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)

  const data = query.file.childMarkdownRemark.frontmatter

  const copyEmail = () => {
    navigator.clipboard.writeText(data.email)
    alert("Email address copied!")
  }

  const phoneDisplay = [
    data.phone.slice(0, 3),
    " ",
    data.phone.slice(3, 6),
    " ",
    data.phone.slice(6),
  ]

  return (
    <section className={cx("wrapper full-width", styles.section)} id="footer">
      <div
        className={cx(
          "wrapper page-width-right flex align-stretch justify-between",
          styles.wrapper
        )}
      >
        <div className={cx("flex column justify-center", styles.text)}>
          <h2>Let's talk.</h2>
          <p>
            <span
              onClick={copyEmail}
              onKeyDown={copyEmail}
              style={{ cursor: `pointer` }}
              role="button"
              tabIndex={0}
            >
              {data.email}
            </span>
            <br></br>
            <a href={`tel:${data.phone}`}>{phoneDisplay}</a>
            <br></br>
            <br></br>
            Find me on{" "}
            <a href={data.linkedin} rel="noopener noreferrer" target="_blank">
              Linkedin
            </a>
          </p>
          <img alt="PdB Consulting logo." className={styles.logo} src={logo} />
        </div>
        <Img
          className={styles.image}
          fluid={data.image.childImageSharp.fluid}
        />
      </div>
    </section>
  )
}

export default FooterSection
